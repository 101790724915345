.SearchBar {
  display: flex;
  flex: 1;
  width: 100%;
  min-width: 340px;
  border: 1px solid #6c6c6c;
  background-color: white;
  border-radius: 3px;
  /* height: 43px; */

  overflow: hidden;
}
.SearchBar input {
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0 1rem;
  color: #181818;
  /* margin-right: 5px; */
  height: 40px;
}
.SearchBar .search_btn {
  height: 100%;
  width: 40px;
  height: unset;
  margin: 2px;
  border: none;
  background-color: var(--primColor);
  border-radius: 3px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: 0;
  min-width: unset !important;
}

.SearchBar .close_btn svg {
  color: #2e2d2d;
  font-size: 20px;
}
.SearchBar .css-jvf1ih-MuiButtonBase-root-MuiButton-root {
  min-width: 0px !important;
}
.SearchBar .css-1l2v9cn {
  min-width: 0px !important;
}

.SearchBar .search_btn svg {
  color: white;
}

@media only screen and (max-width: 600px) {
  .SearchBar {
    min-width: unset;
    width: calc(100% - 20px);
    margin: 10px;
  }
}
