.lgnFormContainer {
  max-width: 395px;
  width: 100%;
}

.lgnFormContainer.regWrapper {
  max-width: 700px;
  width: 100%;
}

.lgnFormContainer .frmHdr {
  margin-bottom: 35px;
  text-align: left;
}

.lgnFormContainer .frmHdr p:nth-child(1) {
  margin-bottom: 15px;
  font-weight: 800;
  font-size: 33px;
}

.lgnFormContainer.authWrapper .primButton {
  margin-top: 0px;
  margin-bottom: 20px;
}

.lgin_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lgin_button .btntext_log {
  text-align: right;
}
.lgnFormContainer .cmRdIptWrpr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.radIpt label {
  border: 1px solid gray;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 14px;
  cursor: pointer;
  font-size: 13px;
}

.radIpt label img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  filter: grayscale(1);
}

.radIpt input {
  display: none;
}

.radIpt input:checked ~ label {
  border: 1px solid var(--primColor);
  color: var(--primColor);
}

.radIpt input:checked ~ label img {
  filter: unset;
}
