.unavailable-status {
  display: inline-block;
  border-radius: 10px;
  padding: 6px;
  /* padding: 4px 0; */
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #cf0404;
  background-color: #ffd6d6;
  width: 90px;
  margin: 0;
}

.available-status {
  display: inline-block;
  border-radius: 10px;
  font-size: 14px;

  padding: 6px;
  /* padding: 4px 0; */

  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #00933b;
  background-color: #cfffe2;
  width: 90px;

  margin: 0;
}
