.layoutWrapper {
  display: flex;
}

.layoutWrapper .viewPortWrapper {
  width: 100%;
  background: #fcfcfc;
}

.layoutWrapper .pageViewPort {
  height: calc(100vh - 90px);
  padding: 25px;
  overflow: auto;
}
