.view_head {
  min-width: 150px;
  padding: 1px 10px 5px 0px;
  font-size: 15px;
  font-weight: 600;
}

.details_list {
  display: flex;
  gap: 50px;
  margin-bottom: 15px;
}

.details_list .view_values {
  font-weight: 400;
  font-size: 15px;
  max-width: 300px !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
