.btnWrpr button {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  min-width: 160px;
}

.btnWrpr button.small {
  width: 160px;
  height: 39px;
  border-radius: 3px;
}

.btnWrpr.primButton button {
  background: var(--primColor);
  color: white;
}
.btnWrpr.terinary button {
  background: #e4e6e9;
  color: black;
  box-shadow: none;
}

.btnWrpr.secButton button {
  border: 1px solid var(--secColor);
  color: #ffffff;
  background: transparent;
  background-color: #363636;
}

button.brdrBtn {
  border: 1px dashed var(--primColor);
  background: #f1fffe;
  border-radius: 10px;
  color: var(--primColor);
  padding: 5px 10px;
}

button.brdrBtn span {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .btnWrpr.primButton button {
    width: 100%;
  }
  
}
