.dlg_hrdr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #a9a9a9;
}
.dlghrde_prnt {
  padding: 10px 12px 10px 17px;
}
.dlg_hrdr p {
  font-weight: 1000;
  color: #202020;
}
.pop_child {
  padding: 5px 20px 20px 20px;
}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
  max-width: 490px !important;
}
