.layout_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: #ffffff;
  padding: 18px;
  box-shadow: 0px 0px 65px 10px #0000000d;
}
.layout_header .rtHdrVw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-left: auto;
}

.layout_header .rtHdrVw .hrdr_avtr {
  display: flex;
  align-items: center;
  gap: 10px;
}

.layout_header .MuiBadge-badge {
  background-color: var(--primColor) !important;
  color: white;
  font-size: 10px;
}

.layout_header .acntDropBtn {
  border-left: 1px solid #efefef;
  color: black;
}

.layout_header .acntDropBtn .intCrcl {
  background: whitesmoke;
  color: var(--primColor);
  width: 38px;
  height: 38px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout_header .acntDropBtn .acntNmInfo {
  text-align: left;
  margin-left: 10px;
  text-transform: initial;
  display: flex;
  flex-direction: column;
  margin-right: 12px;
}

.layout_header .acntDropBtn .acntNmInfo p {
  margin-bottom: 0px;
  font-size: 14px;
}

.layout_header .acntDropBtn .acntNmInfo small {
  font-size: 10px;
  margin: 0px;
  color: gray;
}

.layout_header .acntDropBtn .material-symbols-rounded {
  font-size: 18px;
}

.prflDrop li {
  font-size: 14px;
}
.prflDrop .avat_img {
  width: 10px;
}

.prflDrop li .MuiAvatar-root {
  width: 24px;
  height: 24px;
}

element.style {
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.notification_wrp {
  width: 280px;
  max-height: 250px;
  overflow-y: auto;
}
.notification_wrp .MuiAvatar-root {
  width: 28px;
  height: 28px;
}
.NotifiAlign {
  display: flex;
  gap: 0.8rem;
  cursor: pointer;
  padding: 10px;
}
.NotifiAlign:hover {
  background-color: #f5f5f5;
}
.notification_wrp .noti_cnt {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.notification_wrp .noti_cnt h6 {
  font-size: 11px;
  width: 100%;
  margin-bottom: 0;
  color: #181818;
}
.notification_wrp .noti_cnt p {
  color: #454545;
  font-size: xx-small;
  margin-bottom: 0;
}
