.sideNavComp {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sideNavComp .sideNavHdr {
  height: 100%;
  max-height: 85px;
  min-height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  position: relative;
}

.sideNavComp .sideNavHdr .mnLogo {
  width: 240px;
  height: 160px;
  object-fit: contain;
  transition: ease-in-out 0.2s;
}

.sideNavComp .sideNavHdr .mnLogo.lgoSmll {
  width: 50px;
}

.sideNavComp .toglNvBtn {
  color: white;
  padding: 4px;
  /* border: 1px solid #e0e0e0; */
  z-index: 1;
  background-color: var(--primColor) !important;
  border-radius: 0 50% 50% 0;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 0;
  right: -25px;
}
.sideNavLinks {
  flex: 1;
}
.sideNavComp a {
  text-decoration: none;
  color: #000000de;
}
.sideNavComp a.active-style {
  text-decoration: none;
  color: #009de2;
}
.sideNavComp a.active-style svg {
  color: white;
}

.sideNavComp a:hover {
  text-decoration: none;
}

.sideNavComp .MuiListItemButton-root:hover,
.sideNavComp .active-style .MuiListItemButton-root {
  color: white;
  background-color: var(--primColor);
}

.sideNavComp .MuiListItemButton-root:hover .MuiSvgIcon-root,
.sideNavComp .MuiListItemButton-root:hover .MuiListItemIcon-root,
.sideNavComp .navLink.active .MuiListItemButton-root .MuiSvgIcon-root,
.sideNavComp .navLink.active .MuiListItemButton-root .MuiListItemIcon-root,
.sideNavComp .active-style .MuiListItemButton-root span {
  color: white;
}

.sideNavComp .MuiSvgIcon-root {
  font-size: 23px;
}

.sideNavComp .MuiListItemText-root .MuiTypography-root {
  font-size: 13px;
}

.sideNavComp .MuiPaper-root.MuiDrawer-paper {
  border-right: 1px solid whitesmoke;

  box-shadow: 1px 20px 10px 5px #0000000d;
}

.sideNavComp .MuiList-root .MuiListItem-root,
.sideNavComp .MuiList-root .MuiListItem-root .MuiButtonBase-root {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.sideNavComp .MuiList-root a.active .MuiListItem-root::after,
.sideNavComp .MuiList-root a.active .MuiButtonBase-root::after,
.sideNavComp
  .MuiList-root
  a.active
  .MuiListItem-root
  .MuiButtonBase-root::after {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--primColor);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  transition: linear 15s;
}

.sideNavComp .MuiList-root a.active .MuiListItem-root::after,
.sideNavComp .MuiList-root a.active .MuiButtonBase-root::after,
.sideNavComp
  .MuiList-root
  a.active
  .MuiListItem-root
  .MuiButtonBase-root::after {
  background-color: white;
}

.dropbg {
  background-color: #f9f9f9;
}

@media (max-width: 991px) {
  .sideNavComp .toglNvBtn {
    display: none;
  }
}
