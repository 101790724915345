.dashboard_container {
  width: 100%;
  padding: 7px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.dashboard_container .dash_btn {
  display: flex;

  gap: 15px;
  align-items: center;
}
.dashboard_container h2 {
  font-size: 30px;
  font-weight: 600;
  color: var(--primColor);
}

.accordian_container {
  border: 1px solid #e3e2e2;
  border-radius: 2px;
  margin-top: 15px;
}
.accordian_container h6 {
  font-size: 15px;
  font-weight: 600;
  color: var(--primColor);
  margin-bottom: 0px;
}
.accordian_container
  .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  border-bottom: 1px solid #e3e2e2;
  min-height: 48px;
}
.accordian_container .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
  margin: 10px;
}

.dashboard_container {
  display: flex;
  justify-content: center;
}

.dashboard_grid_view {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.dshStCard {
  flex: 1 1 200px;
  background-color: #f0f0f0;
  padding: 10px;
  box-sizing: border-box;
}

.rtTxtIfo {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
}

.dashboard_container .dshStCard {
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #e8f1ff;
}

.dashboard_container .table_title {
  display: flex;
  justify-content: space-between;

  margin-top: 15px;
}
.dashboard_container .table_title p {
  font-weight: 700;
  color: var(--primColor);
}
.dashboard_container .dshStCard .rtTxtIfo {
  width: 100%;
}

.dashboard_container .dshStCard .rtTxtIfo label {
  font-size: 15px;
}
.dashboard_container .dshStCard label {
  margin-bottom: 8px;
  font-size: 14px;
}

.dashboard_container .dshStCard h3,
.dashboard_container .dshStCard p {
  margin-bottom: 0px;
}

.dashboard_container .dshStCard p {
  font-size: 14px;
  color: red;
}
@media (max-width: 700px) {
  .dashboard_container .dashboard_grid_view {
    grid-template-columns: 2fr 2fr;
  }
}

@media (max-width: 1500px) {
  .dashboard_container .dashboard_grid_view {
    grid-template-columns: 2fr 2fr;
  }
}
