.loginimg_wrpr {
  position: relative;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 47px;
  border-radius: 20px;
  height: 230px;
  width: 450px;
}

.loginWrapper {
  display: grid;
  height: 100%;

  gap: 70px;
  grid-template-columns: 1fr 1fr;

  min-height: 100vh;
}

.loginWrapper .lftMdWrpr .loginimg_wrpr .bck_img img {
  height: 100vh;
  padding: 15px;
  width: 100%;
  object-fit: cover;

  border-radius: 30px;
}
.lftMdWrpr img {
  width: 100%;
}

.loginWrapper .rtFrmWrpr {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .centered {
    width: 300px;
    height: 150px;
  }
  .loginWrapper .rtFrmWrpr {
    height: 100vh;
  }

  .loginWrapper .lftMdWrpr .logoWrapper img {
    width: 90px;
  }

  .loginWrapper .lftMdWrpr .nvTbWrpr {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 25px;
    max-width: 250px;
  }

  .loginWrapper .lftMdWrpr > div.d-flex {
    /* flex-wrap: wrap;
    justify-content: center !important; */
  }

  .loginWrapper .lftMdWrpr::before,
  .loginWrapper .lftMdWrpr::after {
    content: none;
  }

  .loginWrapper .lftMdWrpr .nvTbWrpr button {
    height: 46px;
    border-radius: 20px 20px 0 0;
    font-size: 16px;
  }

  .lgnFormContainer .frmHdr {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .loginWrapper .rtFrmWrpr {
    height: 100vh;
  }
  .lgnFormContainer .cmRdIptWrpr {
    grid-template-columns: 1fr 1fr;
  }

  .loginWrapper {
    display: block;
    height: unset;
    min-height: unset;
  }

  .centered {
    display: none;
  }
  .loginWrapper .lftMdWrpr .loginimg_wrpr .bck_img img {
    display: none;
  }

  .loginWrapper .rtFrmWrpr {
    height: 100vh;
  }

  .lgnFormContainer .radIpt label {
    font-size: 11px;
  }

  .cstmChkBox label span {
    font-size: 13px;
  }

  .rdrTxt {
    font-size: 13px;
  }

  .chkFrgSctn a {
    font-size: 13px;
  }

  .lgnFormContainer .frmHdr p {
    font-size: 14px;
  }

  .lgnFormContainer .frmHdr h2 {
    font-size: 21px;
  }
}
