.customInputWrpr {
  margin-bottom: 23px;
  position: relative;
}
.customInputWrpr input {
  height: 29px;
  color: #837d7d;
}
/* 
.customInputWrpr .errMsg {
  font-size: 10px;
  color: red;
  margin: 0;
} */
.customInputWrpr .errMsg {
  font-size: 10px;
  color: red;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
}

.customInputWrpr .MuiFormControl-root {
  width: 100%;
}
