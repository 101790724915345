.tfp_header {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
  flex-wrap: wrap;
  padding: 8px 0 25px;
}
.tfp_right {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}
.sortable_head {
  cursor: pointer;
}
.Pagination {
  display: flex;
  justify-content: space-between;
  padding: 25px 0px 0px 0px;
}
.Pagination button {
  padding: 0px;
}
.Pagination .pagi_list {
  display: flex;
  gap: 5px;
}
.Pagination .pagi_list p {
  margin-top: 6px;
}
.Pagination .pagi_list button {
  height: 100%;
}
@media (max-width: 768px) {
  .tfp_header {
    flex-direction: column;
    align-items: stretch;
  }
  .tfp_right {
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }
  .tfp_right button {
    width: 100%;
    justify-content: space-between;
  }
}
