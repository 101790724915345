.cstmChkBox .MuiTypography-root {
  font-size: 14px !important;

  margin-bottom: 3px;
}
.cstmChkBox .MuiCheckbox-root {
  padding: 0px 0px 0px 9px !important;
  margin-right: 3px;
}
.cstmChkBox .css-i4bv87-MuiSvgIcon-root {
  width: 23px !important;
  height: 23px !important;
}
/* DEPLOYMENT CLASSNAME CHANGE */
.cstmChkBox .css-vubbuv {
  width: 28px !important;
  height: 28px !important;
}
