:root {
  --primFont: "Montserrat", sans-serif;
  --primColor: #0e479e;
  --secColor: #363636;
  --textColor: #000000;
  --bs-primary: var(--primColor);
}

body {
  color: var(--textColor);
}
a {
  color: var(--primColor);
  font-family: var(--primFont), sans-serif;
}
p {
  font-size: 15px;
  color: var(--textColor);
  margin: 0px;
}

/* table design */
.tblWrpr {
  overflow: auto;
}
.tblWrpr a {
  font-style: italic;
}

.tblWrpr .table {
  font-size: 14px;
  margin-bottom: 0;
}

.tblWrpr .table thead th {
  font-weight: 500;
  color: white;

  background: var(--primColor);
  padding: 15px 19px;
  text-align: left;
}

.tblWrpr .table tr {
  padding: 10px;
  vertical-align: middle;
}

.tblWrpr .table tbody td {
  color: #2e2d2d;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px;
}

.tblWrpr .table thead tr th:first-child {
  border-radius: 6px 0 0 6px;
}

.tblWrpr .table thead tr th:last-child {
  border-radius: 0 6px 6px 0;
}

.tblWrpr .table tr td {
  text-align: left;
}

.tblActions {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.tblActions .tblActIcon svg {
  font-size: 1.3rem;
}

.tblActions .tblActIcon {
  background-color: whitesmoke;
  color: var(--textColor);
  width: 35px;
  height: 35px;
}
.tblActions .tblActIcons {
  background-color: whitesmoke;
  color: var(--textColor);
  width: 35px;
  height: 35px;
  border: 2px solid black;
}

.tblActions .tblActIcon.danger {
  background-color: #ffe1e1;
  color: #c80b0b;
}

button.tblActnBtn {
  border: 1px solid #efefef;
  color: var(--primColor);
  margin-right: 5px;
}

button.tblActnIcn {
  color: var(--primColor);
}
.btnWrpr button {
  height: 40px;
  text-transform: none;
}
.hdrComp {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 11px 0px 16px 0px;
  gap: 0.8rem;
  flex-wrap: wrap;
}
.cntr h6 {
  font-weight: 600;
  color: var(--primColor);
}

.view {
  display: flex;
  align-items: center;
  gap: 11px;
  opacity: 1 !important;
}
.view svg {
  color: #000000;
  font-size: 20px;
}
.view button {
  border: none;
  background-color: #ededed;
  border-radius: 4px;
  padding: 5px;
}

.btnActnBar {
  bottom: -10px;

  background: white;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
}

.btnActnBar button {
  min-width: 140px;
  margin-inline: 0 !important;
}

/* tabs */
.reqTabHead {
  padding: 1px;
  background-color: #e4e6e9;
  border-radius: 3px;
  /* max-width: 480px; */
  display: flex;
}

.justify-content-sb {
  justify-content: space-between;
}
.Fulldlg_title {
  margin: 8px 25px 8px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashFltrTble {
  margin: 15px 25px 15px 25px;
}

.list_hover:hover {
  background-color: #e4ecfa;
}
.bg-clr button {
  background-color: white;
}
.bg-clr ul {
  width: 300px;
}
.bg-clr button {
  height: 40px;
}
.rtActnWrpr {
  display: flex;
  gap: 5px;
}

/* check */
.table_checkbox {
  width: 20px;
  height: 20px;
  color: transparent !important;
}
.table_checkbox_checked {
  width: 20px;
  height: 20px;
}
.table_checkbox svg {
  width: 22px;
  height: 22px;
  border: none !important;
  box-shadow: inset 0 0 3px 1px rgba(34, 34, 34, 0.19);
}
.table_checkbox_checked svg {
  width: 22px;
  height: 22px;
  border: none !important;
  box-shadow: inset 0 0 3px 1px rgba(34, 34, 34, 0.19);
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #1976d2 !important;
}
/* DEPLOYMENT CLASSNAME CHANGE */
.css-zun73v.Mui-checked {
  color: #1976d2 !important;
}

.td {
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.erroStar {
  color: red;
}

.MainFilter {
  margin-bottom: 20px;
  width: 400px;
  padding: 20px;
}
.MainFilter p {
  font-weight: 700;
}
@media (max-width: 768px) {
  .reqTabHead {
    flex-wrap: wrap;
    justify-content: center;
  }
  .rtActnWrpr {
    flex-direction: column;
  }
  .btnActnBar {
    flex-direction: column;
    align-items: stretch;
    flex: 1;
  }
  .btnActnBar > * {
    margin-inline: unset !important;
  }
}

@media only screen and (max-width: 600px) {
  .hdrComp {
    flex-direction: column;
    align-items: stretch;
  }

  .hdrComp > div {
    width: 100%;
    flex-direction: column;
  }

  .rtActnWrpr {
    flex-direction: row;
    margin-top: 10px;
  }
}
